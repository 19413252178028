// Base element style overrides
// stylelint-disable selector-no-type, selector-max-type, selector-max-specificity, selector-max-id

* {
  box-sizing: border-box;
}

::selection {
  color: $white;
  background: $link-color;
}

html {
  @include fs-4;

  scroll-behavior: smooth;
}

body {
  font-family: $body-font-family;
  font-size: inherit;
  line-height: $body-line-height;
  color: $body-text-color;
  background-color: $body-background-color;
  overflow-wrap: break-word;
}

ol,
ul,
dl,
pre,
address,
blockquote,
table,
div,
hr,
form,
fieldset,
noscript .table-wrapper {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
#toctitle {
  margin-top: 0;
  margin-bottom: 1em;
  font-weight: 500;
  line-height: $body-heading-line-height;
  color: $body-heading-color;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

a {
  color: $link-color;
  text-decoration: none;
}

a:not([class]) {
  text-decoration: none;
  background-image: linear-gradient($border-color 0%, $border-color 100%);
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 1px 1px;

  &:hover {
    background-image: linear-gradient(
      rgba($link-color, 0.45) 0%,
      rgba($link-color, 0.45) 100%
    );
    background-size: 1px 1px;
  }
}

code {
  font-family: $mono-font-family;
  font-size: 0.75em;
  line-height: $body-line-height;
}

figure,
pre {
  margin: 0;
}

li {
  margin: 0.25em 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  height: 1px;
  padding: 0;
  margin: $sp-6 0;
  background-color: $border-color;
  border: 0;
}
